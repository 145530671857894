/* Outer card with tan background */
.mb-4.p-4.bg-tan.rounded-lg.shadow-lg {
  background-color: #F5F5DC; /* Tan color */
  margin-bottom: 2rem; /* Increase bottom margin */
  margin-left: 1rem; /* Add some margin on the left if needed */
  margin-right: 1rem; /* Add some margin on the right if needed */
}

/* Inner shopping result card */
.flex.flex-col.p-4.bg-white.rounded-lg.shadow-lg {
  transition: transform 0.2s;
}

/* Inner shopping result card hover effect */
.flex.flex-col.p-4.bg-white.rounded-lg.shadow-lg:hover {
  transform: scale(1.05);
}

/* Enhanced title styling */
h2.text-2xl.font-bold.mb-2.text-left.bg-clip-text.text-transparent.bg-gradient-to-r.from-black.to-blue-600 {
  font-size: 2rem; /* Adjust as needed */
  font-weight: 700; /* Bold */
  background: linear-gradient(90deg, #000000, #1E90FF); /* Gradient color */
  -webkit-background-clip: text; /* Clip the background to text */
  color: transparent; /* Transparent text color to show gradient */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
}

/* Description text styling */
p.text-lg.text-gray-700.mb-4 {
  color: #4A4A4A; /* Dark gray color */
}

/* Other styles */
.result {
  margin-top: 20px;
}

.result img {
  max-width: 200px;
  height: auto;
}

.result h2 {
  font-size: 1.5em;
  margin: 10px 0;
}

.result p {
  font-size: 1.2em;
  margin: 10px 0;
}

.result a {
  color: #61dafb;
  font-size: 1.2em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App ul {
  list-style-type: none;
  padding: 0;
}

.App li {
  margin-bottom: 10px;
}

.App img {
  max-width: 100px;
  height: auto;
  display: block;
  margin: 10px auto;
}

body {
  background-color: #f7fafc;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
  border-left: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
